<div class="window" [style.background-image]="setBG()">
    <div class="head" *ngIf="resMode!=2">
        <div class="col-md-4 left">
            <div class="col-md-12 left">
                <img src="assets/qnote.png" alt="" class="logo">
                <h6 class="text-left pt-1 pl-1" *ngIf="CUSER"> <b class="text-primary">
                    <a target="_blank" *ngIf="CUSER.role == 2">  {{CUSER.fullName}} </a>
                    </b> ({{CUSER.sold}} G)<br/>
                    <a target="_blank" *ngIf="CUSER.role > 2" href="{{website}}BO/App/loginByToken/{{ CUSER.token_user}}">
                        <p class="text-success"> {{CUSER.email}}</p>
                    </a>
                </h6>
            </div>
        </div>
        <div class="col-md-8 right">
            <a href="assets/sample/exemple.zip" title="Telecharjer exemple fichier Excel" class="btn btn-primary right"><i class="nav-icon i-Download1 mr-1"></i></a>
            <button title="Aide" class="btn btn-primary right" *ngIf="resMode!=3" (click)="setPage(3)"><i class="nav-icon i-Light-Bulb mr-1"></i></button>
            <button title="Deconnectez vous" class="btn btn-danger right" *ngIf="CUSER" (click)="logout()"><i class="nav-icon i-Power-2 mr-1"></i></button>
            <button title="Mon profile" class="btn btn-success right" *ngIf="resMode!=4 && CUSER" (click)="showProfil()">
               <i class="nav-icon i-Administrator mr-1"></i>
            </button>
            <button class="btn btn-danger right" (click)="clear()" *ngIf="period?.token_b==''">Reinitialiser</button>
            <button class="btn btn-secondary right" (click)="setPage(10)" *ngIf="resMode!=10" title="Bulletin General">
               <i class="nav-icon i-File-Bookmark mr-1"></i>
             </button>
            <button title="Nouveau bulletin" class="btn btn-success right" (click)="newBulletin()" *ngIf="period?.token_b!=''"><i class="nav-icon i-Add-File mr-1"></i></button>
            <button title="Mode resultat" class="btn btn-primary right" *ngIf="resMode!=1" (click)="setPage(1)"><i class="nav-icon i-File-Edit mr-1"></i></button>
            <button title="Mode d'outils" class="btn btn-warning right" *ngIf="resMode!=0" (click)="setPage(0)">
<i class="nav-icon i-File-Settings mr-1"></i></button>
            <button class="btn btn-primary right" *ngIf="resMode!=2 && !CUSER" (click)="setPage(2)">S'inscrire OU Se connecter</button>
            <button class="btn btn-warning  right" (click)="saveBulletin()" *ngIf="period?.valider==1">
                    <i class="nav-icon i-Data-Save mr-1"></i>
            </button>

        </div>
    </div>

    <div class="body" *ngIf="resMode==0">
        <div class="left-tools">
            <app-tools-left *ngIf="period" (saveEvent)="saveBulletin()" (ouser)="setUser($event)" [acad]="acad" [niv]="niv" [fragment]="fragment" [period]="period" [tools]="tools"></app-tools-left>
        </div>

        <div class="work-space" id="print-section">
            <app-page-ws [tools]="tools" *ngIf="period && period?.valider==1" [period]="period" [coef]="period.coef"></app-page-ws>
            <div class="row" *ngIf="period?.valider==0">

                <div class=" col-md-12">
                    <h2 class="text-center">Configurez votre bulletin</h2>
                </div>
                <div class="col-md-6" *ngIf="acad">
                    <div class="form-group">
                        <label for="acad">Année</label>
                        <select class="form-control" [(ngModel)]="period.annee">
                            <option [value]="item.annee_acad" *ngFor="let item of acad">{{item.annee_acad}}</option>
                    </select>
                    </div>
                </div>

                <div class=" col-md-6" *ngIf="niv">
                    <div class="form-group">
                        <label for="classe">Classe</label>
                        <select class="form-control" [(ngModel)]="period.classe">
                        <option [value]="item.name" *ngFor="let item of niv" >{{item.name}}</option>
                     </select>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="fragment">
                    <div class="form-group">
                        <label for="acad">Periode</label>
                        <select class="form-control" [(ngModel)]="period.name">
                                        <option [value]="item.name" *ngFor="let item of fragment" >{{item.name}}</option>
                                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="acad">Domaine</label>
                        <input type="text" [(ngModel)]="period.etab.domaine" class="form-control" id="">
                    </div>
                </div>
                <div class=" col-md-6">
                    <div class="form-group">
                        <label for="classe">Nom etablissement</label>
                        <input type="text" [(ngModel)]="period.etab.etab_name" class="form-control" id="">
                    </div>
                </div>





                <div class="info mt-4 mb-4 {{(period.tools)? 'border': '' }} pt-4 pb-4 pl-1 pr-1" attr.style="height:{{period.infoHeight}}%;">
                    <div class="periode_name" attr.style="font-size:{{period.titreInfoSizetext}}px text-align:center;">
                        {{ period?.tbull }} {{period.name}}
                    </div>
                    <div class="period_info">
                        <div class="block-a ">

                            <div class="class-period ">
                                <b>{{ period?.tclasse }}</b> {{period?.classe}} {{period?.etab?.domaine}} {{bulletin?.user.section}}
                            </div>
                        </div>
                        <div class="block-b">
                            <div class="year-period">
                                {{period?.annee}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-md-12">
                    <div class="alert alert-warning">
                        <p class="text-center">
                            Assurez-vous de bien verifier les informations ci-dessus, sinon après la validation vous ne pourrez plus les modifier.
                        </p>
                    </div>
                </div>
                <div class=" col-md-12">
                    <button title="Valider" *ngIf="CUSER" class="btn btn-primary right" (click)="saveData()">
                         Valider
                    </button>
                    <button class="btn btn-primary right" *ngIf="!CUSER" (click)="resMode=2">S'inscrire OU Se connecter</button>
                </div>
            </div>
        </div>

        <div class="right-tools">
            <app-tools-right *ngIf="period" [period]="period"></app-tools-right>
        </div>
        <!-- end body -->
    </div>

    <!-- resultat -->
    <div class="body" *ngIf="resMode==1 && period">
        <div class="tools-inline">
            <div class="row">
                <div class="col-md-2 p-2  ">
                    <label class="switch switch-primary">
                      <span>Note seulement</span>
                      <input type="checkbox"  [(ngModel)]="period.note_show">
                      <span class="slider"></span>
                    </label>
                </div>
                <div class="col-md-3 p-2  ">
                    <label class="switch switch-primary">
                      <span>Note par matiere & statistique</span>
                      <input type="checkbox"  [(ngModel)]="period.noteMat">
                      <span class="slider"></span>
                    </label>
                </div>
                <div class="col-md-7">
                    <button title="Enregistrer" class="btn btn-success mr-1 right mr-1 ml-1" (click)="saveBulletin()" *ngIf="period?.valider==1">Sauvegarde</button>
                    <button title="Reformater Resultat" class="btn btn-primary mr-1 ml-1 right" (click)="ReformaterRes()">Reformatés</button>
                    <button title="Normaliser resultat" class="btn btn-primary mr-1 ml-1 right" (click)="ReformaterRes2()">Normalisé </button>
                    <button title="Aide" (click)="initResults() " class="btn btn-danger  mr-1 ml-1   right">Réinitialisés</button>
                    <button *ngIf="!period.noteMat" class="btn btn-secondary ml-2 mr-2 right" styleSheetFile="assets/print/pstyle.css" printSectionId="print-section-palmares" ngxPrint>Imprimmer</button>
                </div>
            </div>

        </div>
        <div class="work-space-results" id="print-section-palmares">
            <app-results-ws [period]="period"></app-results-ws>
        </div>
    </div>

    <!-- Login -->
    <div *ngIf="resMode==2 ">

        <div class="auth-layout-wrap">
            <div class="auth-content">
                <div class="card o-hidden">

                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h3 class="mt-3  text-center">QNote</h3>
                            <h5 class="mt-1 mb-3  text-center">Bienvenue sur notre plateforme de conception de bulletin rapide scolaire </h5>
                        </div>
                        <div class="col-md-6" style="margin-left: auto; margin-right:auto;">
                            <div class="p-2">

                                <div class="separator-breadcrumb border-top"></div>
                                <h1 class="mb-1 text-16 text-center">Ouvrir une session</h1>
                                <h3 class="mb-3 text-12 text-center">Vous avez déjà un compte Qnote</h3>
                                <div class="col-md-12">
                                    <app-alert [alert]="alert" pos="11"></app-alert>
                                </div>
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <label for="username">Email</label>
                                        <input formControlName="email" [ngClass]="{ 'is-invalid': submitted1 && f.email.errors }" class="form-control form-control-rounded" type="email">
                                        <div *ngIf="submitted1 && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Vous devez ajouter un email</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="username">Mot de passe</label>
                                        <input formControlName="pass" [ngClass]="{ 'is-invalid': submitted1 && f.pass.errors }" class="form-control form-control-rounded" type="password">
                                        <div *ngIf="submitted1 && f.pass.errors" class="invalid-feedback">
                                            <div *ngIf="f.pass.errors.required">Vous devez ajouter un mot de passe</div>
                                        </div>
                                    </div>

                                    <button [disabled]="loading[0]" class="btn btn-primary btn-block btn-rounded mt-2">
                                    <span *ngIf="loading[0]" class="spinner-border spinner-border-sm mr-1"></span>
                                     se connecter
                                    </button>
                                </form>
                            </div>
                        </div>
                        <!-- Sign'up -->
                        <div class="col-md-6" style="margin-left: auto; margin-right:auto; border-left: 1px solid#faf4ff;">
                            <div class="p-2">
                                <div class="separator-breadcrumb border-top"></div>
                                <h1 class="mb-1 text-16 text-center">Enregistrez-vous</h1>
                                <h3 class="mb-3 text-12 text-center">Vous etes nouveau sur Qnote</h3>
                                <div class="col-md-12">
                                    <app-alert [alert]="alert" pos="10"></app-alert>
                                </div>
                                <form [formGroup]="regForm" (ngSubmit)="onSubmitReg()">
                                    <div class="form-group">
                                        <label for="username">Nom & prénom</label>
                                        <input formControlName="fullname" [ngClass]="{ 'is-invalid': submitted && r.fullname.errors }" class="form-control form-control-rounded" type="text">
                                        <div *ngIf="submitted && r.fullname.errors" class="invalid-feedback">
                                            <div *ngIf="r.fullname.errors.required">Vous devez ajouter votre nom & prenom</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="phone">Téléphone</label>
                                        <input formControlName="phone" [ngClass]="{ 'is-invalid': submitted && r.phone.errors }" class="form-control form-control-rounded" type="text">
                                        <div *ngIf="submitted && r.phone.errors" class="invalid-feedback">
                                            <div *ngIf="r.phone.errors.required">Vous devez ajouter votre numero de telephone</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="username">Email</label>
                                        <input formControlName="username" [ngClass]="{ 'is-invalid': submitted && r.username.errors }" class="form-control form-control-rounded" type="email">
                                        <div *ngIf="submitted && r.username.errors" class="invalid-feedback">
                                            <div *ngIf="r.username.errors.required">Vous devez ajouter un email</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="username">Mot de passe</label>
                                        <input formControlName="password" [ngClass]="{ 'is-invalid': submitted && r.password.errors }" class="form-control form-control-rounded" type="password">
                                        <div *ngIf="submitted && r.password.errors" class="invalid-feedback">
                                            <div *ngIf="r.password.errors.required">Vous devez ajouter un mot de passe</div>
                                        </div>
                                    </div>

                                    <button [disabled]="loading[1]" class="btn btn-primary btn-block btn-rounded mt-2">
                                          <span *ngIf="loading[1]" class="spinner-border spinner-border-sm mr-1"></span>
                                             S'enregistrer
                                     </button>
                                </form>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>

    <!-- page d'aide -->
    <div class="body" *ngIf="resMode==3">
        <div class="tools-inline">
            <h1 style="text-align: center;">Bien démarrer avec QuicNote</h1>
        </div>

        <div class="work-space-results">

            <iframe style="width: 98%; margin:1%;" height="510" src="https://www.youtube.com/embed/OEJfCvD8jYc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
    </div>

    <!-- page mon compte-->
    <div class="body" *ngIf="resMode==4">

        <div class="left-tools">
            <h5 class="text-left">Informations personnelles</h5>
            <div class="row">

                <div class="col-md-12">
                    <div class="col-md-12 row">
                        <app-alert [alert]="alert" pos="15"></app-alert>
                    </div>
                    <div class="form-group">
                        <label>Nom & Prénom</label>
                        <input type="text" class="form-control" [(ngModel)]="CUSER.fullName">
                    </div>
                    <div class="form-group">
                        <label>Nom etablissement</label>
                        <input type="text" class="form-control" [(ngModel)]="CUSER.etab_name">
                    </div>
                    <div class="form-group">
                        <label>Téléphone</label>
                        <input type="text" class="form-control" [(ngModel)]="CUSER.phone">
                    </div>
                    <button class="btn btn-primary" (click)="changeName()">Modifier</button>
                </div>
            </div>
            <hr/>
            <div class="row" *ngIf="CUSER.valider_user==0">
                <div class="col-md-12">
                    <h6>Valider votre compte</h6>
                </div>

                <div class="col-md-12">
                    <div class="col-md-12 row">
                        <app-alert [alert]="alert" pos="20"></app-alert>
                    </div>
                    <div class="form-group">
                        <label>Entrez votre pin</label>
                        <input type="text" class="form-control" [(ngModel)]="pin">
                    </div>
                    <button class="btn btn-primary" (click)="validerUser()">Modifier</button>
                </div>

                <div class="col-md-12 mt-4">
                    <div class="col-md-12 row">
                        <app-alert [alert]="alert" pos="16"></app-alert>
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" class="form-control" [(ngModel)]="CUSER.email">
                    </div>
                    <button class="btn btn-primary" (click)="changeEmail()">Modifier</button>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-12">
                    <h6>Changer votre mot de passe</h6>
                </div>
                <div class="col-md-12">
                    <app-alert [alert]="alert" pos="14"></app-alert>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="" class="pass">Ancien mot de passe</label>
                        <input type="password" class="form-control" [(ngModel)]="change_pass.password">
                    </div>
                    <div class="form-group">
                        <label for="" class="pass">Nouveau mot de passe</label>
                        <input type="password" class="form-control" [(ngModel)]="change_pass.pass_1">
                    </div>
                    <div class="form-group">
                        <label for="" class="pass">Confirmer le nouveau mot de passe</label>
                        <input type="password" class="form-control" [(ngModel)]="change_pass.pass_2">
                    </div>
                    <button class="btn btn-primary" (click)="changePass()">Modifier</button>
                </div>
            </div><br>
        </div>
        <div class="work-space">
            <h3 class="text-center">Vos bulletins</h3>
            <hr/>
            <div class="col-md-12">
                <app-alert [alert]="alert" pos="13"></app-alert>
            </div>
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Année</th>
                        <th scope="col">Classe</th>
                        <th scope="col">Période</th>
                        <th scope="col">Payé</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of periods">
                        <td>{{item?.annee}}</td>
                        <td>{{item?.classe}}</td>
                        <td>{{item?.periode}}</td>
                        <td>
                            <span class="badge badge-danger" *ngIf="item?.pay==0">Non payé</span>
                            <span class="badge badge-success" *ngIf="item?.pay==1">Payé</span>
                        </td>
                        <td>
                            <button title="Ouvrir ce bulletin" class="btn btn-primary m-2" (click)="open(item?.token_b)" *ngIf="period?.token_b != item?.token_b">
                                <i class="nav-icon i-Eye"></i>
                            </button>
                            <button title="Dupliquer ce bulletin" class="btn btn-success m-2" (click)="duplicate(item?.token_b)">
                                <i class="nav-icon i-Duplicate-Layer"></i>
                            </button>
                            <button title="Supprimer ce bulletin" class="btn btn-danger m-2" (click)="delBulletin(item?.id_bulletin)" *ngIf="period?.token_b != item?.token_b">
                                <i class=" nav-icon i-Close "></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="right-tools ">

            <div class="row ">
                <div class="col-md-12 ">
                    <div class="alert alert-warning ">
                        <p class="text-center ">
                            Indisponible pour le moment
                        </p>
                    </div>
                </div>
                <div class="col-md-12 ">
                    <h6>Ajouter credit</h6>
                </div>
                <div class="col-md-12 ">
                    <div class="form-group ">
                        <input type="text " class="form-control ">
                    </div>
                    <button class="btn btn-primary ">Ajout credit</button>
                </div>
            </div>

        </div>
    </div>

    <!-- BULLETIN GENERAL -->
    <div class="body" *ngIf="resMode==10">
        <app-bulletin [acad]="acad" [niv]="niv"></app-bulletin>
    </div>


</div>

<div>
    <app-tuto></app-tuto>
</div>
