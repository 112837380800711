
export class User {
name: string;
code: string;
section: string;
public sexe: boolean;
 public constructor(code , name, s, x) {
 this.code = code ;
 this.name = name;
 this.section = s;
 this.sexe = x;
}

}
