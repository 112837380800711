import { Bulletin } from './Bulletin';
import { Cours } from './Cours';
import { Etab } from './Etab';
import { Results } from './Results';
import { User} from './User';

export class Period {
  annee: string;
  name: string;
  classe: string;
  users: User []  = [];
  cours: Cours[]  = [];
  bulletins: Bulletin [] = [];
  coef: number;
  etab: Etab;
  noteMat = false;
  tools;
  showId;
  tmat;
  tcoef;
  tnote;
  tobj;
  tval;
  tbull;
  tut;
  textAlign;
  textPos;
  signa_di;
  signa_pa;
  signa_ti;
  smargin;
  heightImg;
  widthImg;
  posImg;
  headHeight;
  titreWidth;
  titreFontSize;
  detailFontSize;
  detailWidth;
  logoMarginTop;
  infoHeight;
  resultHeight;
  mentionHeight;
  signaHeight;
  fullname;
  tclasse;
  mentionMarginTop;
  tableWidth;
  mentionWidth;
  objWidth;
  token_b;
  pay;
  date_pay;
  valider;
  modif;
  msg_rep;
  msg_exc;
  msg_echec;
  msg_reu;
  max_cours;
  max_users;
  is_over;
  is_reprise;
  note_show;
  imageSrc: string | ArrayBuffer;
  detailsTextMt;
  heightb;
  widthb;
  orient;
  domaine;
  moy_classe = 0;
  palm_gen = false;
  frags = "Ctrl 1/Ctrl 2/Ctrl 3/Ctrl 4";
  stat = false;
  public constructor(annee, name, classe, b: Bulletin[], etab: Etab) {
    this.frags = "Ctrl 1/Ctrl 2/Ctrl 3/Ctrl 4";
    this.palm_gen = false;
    this.orient=true;
    this.max_cours = 50;
    this.max_users = 1000;
    this.pay = 0;
    this.token_b = null;
    this.valider = 0;
    this.annee = annee;
    this.name = name;
    this.classe = classe;
    this.bulletins = b;
    this.coef = 10;
    this.etab = etab;
    this.tools = true;
    this.showId = true;
    this.tmat = 'Matieres';
    this.tcoef = 'Coéfficient';
    this.tnote = 'Note';
    this.tobj = 'Objets';
    this.tval = 'Valeur';
    this.tbull = 'Bulletin';
    this.tut = true;
    this.signa_di = 'Signature du directeur';
    this.signa_pa = 'Signature du parent';
    this.signa_ti = 'Signature du titulaire';
    this.smargin = 19;
    this.mentionMarginTop = 19;
    this.heightImg = 50;
    this.widthImg = 50;
    this.heightb = 100;
    this.widthb = 100;
    this.posImg = 'center';
    this.textAlign = 'center';
    this.textPos = '';
    this.headHeight = 13;
    this.titreWidth = 98;
    this.detailWidth = 98;
    this.logoMarginTop = 0;
    this.infoHeight = 11;
    this.resultHeight= 40;
    this.mentionHeight=10;
    this.signaHeight = 11;
    this.fullname = "Nom & Prénom";
    this.tclasse = "Classe";
    this.tableWidth = 99;
    this.mentionWidth = 48;
    this.objWidth = 99;
    this.titreFontSize = 25;
    this.detailFontSize = 15;
    this.modif = true;
    this.msg_rep ="";
    this.msg_exc ="Excelent(e)";
    this.msg_echec ="Echèc";
    this.msg_reu = "Réussite";
    this.is_over = "false";
    this.is_reprise = false;
    this.note_show = false;
    this.detailsTextMt = 1;
    this.headTableTextsize =12;
    this.titreInfoSizetext = 12;
    this.marginb = 0;
    this.margint = 0;
    this.domaine = "";
    this.noteMat = false;
   }
   margint
   marginb;
   titreInfoSizetext;
   headTableTextsize;
   bmarginb = 1;
   setOneUser(u:User){
   this.users.push(u);
   }

  setOneCours(c:Cours){
    this.cours.push(c);
  }
  setDomaine(d: any) {
    this.domaine = d;
  }
}
