export class XCours {
code: string = "";
name: string = "";
note_total: number = 0;
note_pass:  number = 0;
note_rep:   number = 0;
note_exc:   number = 0;
is_calc: number = 0;
is_number: number = 1;
public constructor() {
}
}
